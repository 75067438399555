import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import './ArticlePage.css';

function ArticlePage() {
  const { id } = useParams(); // 获取 URL 中的文章 ID
  const [article, setArticle] = useState({ title: '', content: '', socialmedia: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 替换为您的服务器地址
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://www.newtritional.com/articles/noau/one?id=${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error('获取文章失败：', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <div>加载中...</div>;
  }

  return (
    <div className="article-container">
      <Helmet>
        <title>{article.title}</title>
        {/* Twitter Cards 配置 */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content="Deep Research 出品" />
        <meta name="twitter:image" content="/images/dr.png" />

        {/* Open Graph 配置 */}
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content="Deep Research 出品" />
        <meta property="og:image" content="/images/dr.png" />
        <meta property="og:url" content={`https://www.ainewtrition.com/dr/article/${id}`} />
      </Helmet>

      <h1 className="article-title">{article.title}</h1>
      <div
        className="article-content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}
      ></div>
    </div>
  );
}

export default ArticlePage;